import React from 'react'
import Lottie from 'react-lottie';
import AnimationData from './asserts/animationwave.json'

export default class LottieControl extends React.Component {

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: AnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <div className='lottie-container'>
      <Lottie options={defaultOptions} height={250}/>
      <Lottie options={defaultOptions} height={250}/>
      <Lottie options={defaultOptions} height={250}/>
      <Lottie options={defaultOptions} height={250}/>
    </div>
  }
}