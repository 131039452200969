import { useState } from "react";
import tickicon from "./asserts/tick.svg";

const Form = () => {
    const [emailStatus, setEmailStatus] = useState(true);
    const [state1, setState1] = useState('');
    const [state2, setState2] = useState('');
    const [state3, setState3] = useState('');
    const [state5, setState5] = useState('');

    const handleState1Change = (e) => {
        setState1(e.target.value);
    };

    const handleState2Change = (e) => {
        setState2(e.target.value);
    };

    const handleState3Change = (e) => {
        setState3(e.target.value);
    };

    const handleState5Change = (e) => {
        setState5(e.target.value);
    };

    const sendEmail = async () => {
        try {
            if (state1 && state2 && state3 && state5) {
                let l = [];
                l.push("First Name :" + state1);
                l.push("Last Name: " + state2);
                l.push("Email ID: " + state3);
                l.push("Message:" + state5);

                const recipients = ['hari.irulappan@bosonlabs.net'];
                const config = {
                    SecureToken: "eb7ffe7c-0c77-43b8-81aa-619211c76257",
                    To: recipients,
                    From: 'blackcollateral@gmail.com',
                    Subject: "Boson  Labs",
                    Body: l.join("<br />"),
                };
                if (window.Email) {
                    const result = await window.Email.send(config);
                    if (result.includes("OK")) {
                        return true; // Indicate success
                    } else {
                        console.error(result);
                        return false; // Indicate failure
                    }
                }
            } else {
                let errorMessage = "Please fill in the following fields:\n";
                if (!state1) errorMessage += "- First Name\n";
                if (!state2) errorMessage += "- Last Name\n";
                if (!state3) errorMessage += "- Email ID\n";
                if (!state5) errorMessage += "- Message\n";

                console.error(errorMessage);
                return false; // Indicate failure
            }
        } catch (error) {
            console.error(error);
            return false; // Indicate failure
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const emailSentSuccessfully = await sendEmail();
            if (emailSentSuccessfully) {
                setEmailStatus(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {emailStatus ? (
                <form>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" onChange={handleState1Change} />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" onChange={handleState2Change} />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" onChange={handleState3Change} />
                    </div>
                    <div className="form-group">
                        <label>Message</label>
                        <textarea rows="4" onChange={handleState5Change}></textarea>
                    </div>
                    <button className="btn-submit" onClick={handleSubmit}>Submit</button>
                </form>
            ) : (
                <div className="form-group">
                    <center>
                        <img src={tickicon} alt="" />
                    </center>
                    <p style={{ color: "#000000", textAlign: 'center' }}>
                        We've received your message, and a member of our team will be in touch with you soon. Thank you!
                    </p>
                </div>
            )}
        </>
    );
};

export default Form;
